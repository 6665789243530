//@ts-nocheck
import React, { FC, useState, useEffect } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import {
  Description,
  DescriptionItem,
  Button,
  ArrowLeftIcon,
  Collapse,
  EditIcon,
  Modal,
  Radio,
  Input,
} from '@gamesb42/ui-kit';

import { getToken } from 'helpers/token';
import urls from 'constants/urls';
import { formatAmount, getCurrencyWithCode, getNameCurrencyOfCode } from 'helpers/formatters';
import { BankAccount, Company, CompanyContact, CompanyForm, CompanyKeyEnum } from 'types/CompanyTypes';
import { Currency } from 'types/Currency';
import Country from 'components/Country';

import DescriptionBankAccount from './DescriptionBankAccount';
import styles from './styles.module.scss';
import { getAccountsByIdCompany } from 'api/accounts';
import { COMPANY_FORM_NAME } from 'constants/company';

interface BankAccountWithBalance extends BankAccount {
  balance: number;
}

const getBalanceValue = (data: BankAccountWithBalance) =>
  `${Number(data?.balance) < 0 ? '-' : ''}${getCurrencyWithCode(Number(data?.currency))}${formatAmount(
    Math.abs(data?.balance || 0),
  )}`;

type PropsT = {
  company: Company;
};

const General: FC<PropsT> = (props) => {
  const [accounts, setAccounts] = useState<BankAccountWithBalance[]>([]);
  const history = useHistory();
  const { company } = props;
  const { t } = useTranslation();

  const [taxTreaty, setTaxTreaty] = useState(
    company?.tax_treaty === '-' || company?.tax_treaty === null ? '-' : company?.tax_treaty ? 'yes' : 'no',
  );

  const [googleDocs, setGoogleDocs] = useState(company?.google_drive_url);

  sessionStorage.setItem(
    'prevTaxTreaty',
    company?.tax_treaty === '-' || company?.tax_treaty === null ? '-' : company?.tax_treaty ? 'yes' : 'no',
  );

  sessionStorage.setItem('prevGoogleDocs', company?.google_drive_url);

  const { companyId } = useParams();

  const contacts = (company[CompanyKeyEnum.CONTACTS] || []).map(
    ({ contact_phone, full_name, position, email }: CompanyContact) => [
      {
        title: t('forms.companies.contacts.contactFullName'),
        content: full_name,
      },
      {
        title: t('forms.companies.contacts.position'),
        content: position,
      },
      {
        title: t('forms.companies.contacts.email'),
        content: email,
      },
      {
        title: t('forms.companies.contacts.phoneNumber'),
        content: contact_phone,
      },
    ],
  );
  const mainAccountEur = company[CompanyKeyEnum.BANK_DETAILS]?.find(
    (account: BankAccount) => account[CompanyKeyEnum.MASTER] && account[CompanyKeyEnum.CURRENCY] === Currency.EUR,
  );
  const mainAccountUsd = company[CompanyKeyEnum.BANK_DETAILS]?.find(
    (account: BankAccount) => account[CompanyKeyEnum.MASTER] && account[CompanyKeyEnum.CURRENCY] === Currency.USD,
  );
  const additionalAccountsEur =
    company[CompanyKeyEnum.BANK_DETAILS]?.filter(
      (account: BankAccount) => !account[CompanyKeyEnum.MASTER] && account[CompanyKeyEnum.CURRENCY] === Currency.EUR,
    ) || [];
  const additionalAccountsUsd =
    company[CompanyKeyEnum.BANK_DETAILS]?.filter(
      (account: BankAccount) => !account[CompanyKeyEnum.MASTER] && account[CompanyKeyEnum.CURRENCY] === Currency.USD,
    ) || [];

  const getDataSourceBalance = () => {
    const result = [];

    const accountWithBalanceEur = accounts.find((account) => getNameCurrencyOfCode(account.currency) === Currency.EUR);
    const accountWithBalanceUSD = accounts.find((account) => getNameCurrencyOfCode(account.currency) === Currency.USD);

    if (accountWithBalanceEur) {
      result.push({
        title: `${t('forms.companies.balance')} ${getNameCurrencyOfCode(accountWithBalanceEur.currency)}`,
        content: getBalanceValue(accountWithBalanceEur),
        contentClassName: styles.contentBalance,
      });
    }

    if (accountWithBalanceUSD) {
      result.push({
        title: `${t('forms.companies.balance')} ${getNameCurrencyOfCode(accountWithBalanceUSD?.currency)}`,
        content: getBalanceValue(accountWithBalanceUSD),
        contentClassName: styles.contentBalance,
      });
    }

    if (result.length === 0) {
      result.push({
        title: `${t('forms.companies.balance')}`,
        content: 'N/A',
      });
    }

    return result;
  };

  useEffect(() => {
    if (company[CompanyKeyEnum.ID]) {
      getAccountsByIdCompany(company[CompanyKeyEnum.ID])
        .then((results) =>
          setAccounts(results.filter((result: { type: string }) => result.type === 'counterparty-client')),
        )
        .catch(console.error);
    }
  }, [company[CompanyKeyEnum.ID]]);

  return (
    <div className={styles.row}>
      <div className={styles.twoColumnsBlock}>
        <Description dataSource={getDataSourceBalance()} />

        {!['revenue-source', 'third-party'].includes(company.type || '') && (
          <Description
            dataSource={[
              {
                title: t('forms.companies.operatingCurrency'),
                content: company[CompanyKeyEnum.OPERATING_CURRENCY] || 'Unknown',
              },
            ]}
          />
        )}
      </div>
      <div className={styles.twoColumnsBlock}>
        <Description
          dataSource={
            [
              {
                title: t('forms.companies.companyInfo.name'),
                content: company[CompanyKeyEnum.NAME],
              },
              {
                title: t('forms.companies.companyInfo.legalName'),
                content: company[CompanyKeyEnum.LEGAL_NAME],
              },
              company[CompanyKeyEnum.COMPANY_FORM] && {
                title: t('company.companyForm'),
                content: COMPANY_FORM_NAME[company[CompanyKeyEnum.COMPANY_FORM] as CompanyForm],
              },
              {
                title: t('forms.companies.companyInfo.registrationNumber'),
                content: company[CompanyKeyEnum.REGISTRATION_NUMBER],
              },
              {
                title: t('forms.companies.companyInfo.taxNumber'),
                content: company[CompanyKeyEnum.TAX_NUMBER],
              },
              {
                title: t('forms.companies.companyInfo.webSite'),
                content: company[CompanyKeyEnum.WEBSITE],
              },
            ].filter(Boolean) as DescriptionItem[]
          }
        />
        <Description
          dataSource={[
            {
              title: t('forms.companies.companyInfo.type'),
              content: t(`company.types.${company[CompanyKeyEnum.TYPE]}`, 'Unknown type') as string,
            },
            {
              title: t('forms.companies.companyInfo.country'),
              content: <Country countryCode={company[CompanyKeyEnum.COUNTRY] || ''} />,
            },
            {
              title: t('forms.companies.companyInfo.addressFirst'),
              content: [company[CompanyKeyEnum.ADDRESS], company[CompanyKeyEnum.ZIP], company[CompanyKeyEnum.CITY]]
                .filter(Boolean)
                .join(', '),
            },
            {
              title: 'Tax Treaty',
              content: (
                <div style={{ width: '100%', padding: '0 8px 0 0', display: 'flex', justifyContent: 'space-between' }}>
                  <span>{taxTreaty}</span>
                  <Button
                    type="iconBtn"
                    size="small"
                    onClick={() => {
                      Modal.info({
                        title: 'Indicate Tax Treaty',
                        okText: 'Submit',
                        content: (
                          <Radio.Group>
                            <Radio
                              value="yes"
                              onChange={() => {
                                setTaxTreaty('yes');
                                sessionStorage.setItem('taxTreaty', 'yes');
                              }}
                            >
                              Yes
                            </Radio>
                            <Radio
                              value="no"
                              onChange={() => {
                                setTaxTreaty('no');
                                sessionStorage.setItem('taxTreaty', 'no');
                              }}
                            >
                              No
                            </Radio>
                          </Radio.Group>
                        ),
                        onOk: async () => {
                          const myHeaders = new Headers();
                          myHeaders.append('authorization', `Bearer ${getToken()}`);
                          const result = await fetch(`${process.env.REACT_APP_SCA_URL}/v1/companies/${companyId}`, {
                            method: 'GET',
                            headers: myHeaders,
                          });
                          let resData = await result.json();

                          resData.details.tax_treaty = sessionStorage.getItem('taxTreaty');
                          const raw = JSON.stringify(resData);

                          const myHeaders2 = new Headers();
                          myHeaders2.append('authorization', `Bearer: ${getToken()} `);
                          myHeaders2.append('content-type', 'application/json');

                          fetch(`${process.env.REACT_APP_SCA_URL}/v1/companies/${companyId}`, {
                            method: 'PUT',
                            headers: myHeaders2,
                            body: raw,
                            redirect: 'follow',
                          }).catch((error) => console.error(error));
                        },
                        onCancel: () => {
                          setTaxTreaty(sessionStorage.getItem('prevTaxTreaty'));
                        },
                      });
                    }}
                    icon={<EditIcon />}
                  />
                </div>
              ),
            },
            {
              title: t('forms.companies.contacts.email'),
              content: company[CompanyKeyEnum.EMAIL],
            },
            {
              title: t('forms.companies.contacts.phoneNumber'),
              content:
                company[CompanyKeyEnum.PHONE][0] === '+'
                  ? company[CompanyKeyEnum.PHONE]
                  : '+' + company[CompanyKeyEnum.PHONE],
            },
          ]}
        />
      </div>

      <div className={styles.googleDocs}>
        <div className={styles.label}>Google Docs:</div>
        <div
          className={styles.value}
          style={{
            color: googleDocs !== '' ? '#5227cb' : '#4E5169',
            textDecorationLine: googleDocs !== '' ? 'underline' : 'none',
          }}
        >
          {googleDocs !== '' && (
            <a href={googleDocs} target="_blank">
              {googleDocs}
            </a>
          )}
          {googleDocs === '' && <div>-</div>}
          <Button
            type="iconBtn"
            size="small"
            onClick={() => {
              Modal.info({
                title: 'Add link to Google Doc',
                okText: 'Save',
                content: <GoogleInput googleDocs={googleDocs} setGoogleDocs={setGoogleDocs} />,
                onOk: async () => {
                  const myHeaders = new Headers();
                  myHeaders.append('authorization', `Bearer ${getToken()}`);
                  const result = await fetch(`${process.env.REACT_APP_SCA_URL}/v1/companies/${companyId}`, {
                    method: 'GET',
                    headers: myHeaders,
                  });
                  let resData = await result.json();
                  resData.details.google_drive_url = sessionStorage.getItem('googleDocs');
                  const raw = JSON.stringify(resData);
                  const myHeaders2 = new Headers();
                  myHeaders2.append('authorization', `Bearer: ${getToken()} `);
                  myHeaders2.append('content-type', 'application/json');
                  fetch(`${process.env.REACT_APP_SCA_URL}/v1/companies/${companyId}`, {
                    method: 'PUT',
                    headers: myHeaders2,
                    body: raw,
                    redirect: 'follow',
                  })
                    .then(() => {
                      setGoogleDocs(sessionStorage.getItem('googleDocs'));
                      sessionStorage.setItem('prevGoogleDocs', sessionStorage.getItem('googleDocs'));
                    })
                    .catch(() => {
                      sessionStorage.setItem('googleDocs', sessionStorage.getItem('prevGoogleDocs'));
                      setGoogleDocs(sessionStorage.getItem('prevGoogleDocs'));
                    });
                },
                onCancel: () => {},
              });
            }}
            icon={<EditIcon />}
          />
        </div>
      </div>

      <Collapse>
        {!['revenue-source', 'third-party'].includes(company.type || '') && (
          <Collapse.Panel header={t('forms.companies.contacts.title')} key="contacts">
            <div className={styles.twoColumnsBlock}>
              <Description
                dataSource={[
                  {
                    title: t('forms.companies.contacts.signerFullName'),
                    content: company[CompanyKeyEnum.SIGNER_FULL_NAME],
                  },
                  {
                    title: t('forms.companies.contacts.signerPosition'),
                    content: company[CompanyKeyEnum.SIGNER_POSITION],
                  },
                  {
                    title: t('forms.companies.contacts.signerEmail'),
                    content: company[CompanyKeyEnum.SIGNER_EMAIL],
                  },
                ]}
              />
              <div className={styles.row}>
                {contacts.map((dataSource: DescriptionItem[]) => (
                  <Description dataSource={dataSource} />
                ))}
              </div>
            </div>
          </Collapse.Panel>
        )}
        {(mainAccountEur || mainAccountUsd) && (
          <Collapse.Panel header={t('forms.companies.bankDetails.mainBankAccounts')} key="mainBankAccounts">
            <div className={styles.twoFlexColumnsBlock}>
              {mainAccountEur ? (
                <div style={{ width: '48%' }}>
                  <DescriptionBankAccount account={mainAccountEur} />
                </div>
              ) : (
                <div />
              )}
              {mainAccountUsd ? (
                <div style={{ width: '48%' }}>
                  <DescriptionBankAccount account={mainAccountUsd} />
                </div>
              ) : (
                <div />
              )}
            </div>
          </Collapse.Panel>
        )}
        {(additionalAccountsEur.length > 0 || additionalAccountsUsd.length > 0) && (
          <Collapse.Panel header={t('forms.companies.bankDetails.additionalBankAccounts')} key="additionalBankAccounts">
            <div className={styles.twoColumnsBlock}>
              {additionalAccountsEur.length > 0 ? (
                <div className={styles.row}>
                  {additionalAccountsEur.map((account: BankAccount) => (
                    <DescriptionBankAccount account={account} />
                  ))}
                </div>
              ) : (
                <div />
              )}
              {additionalAccountsUsd.length > 0 ? (
                <div className={styles.row}>
                  {additionalAccountsUsd.map((account: BankAccount) => (
                    <DescriptionBankAccount account={account} />
                  ))}
                </div>
              ) : (
                <div />
              )}
            </div>
          </Collapse.Panel>
        )}
      </Collapse>
      <Button
        className={styles.generalBottom}
        type="primary"
        icon={<ArrowLeftIcon size={16} />}
        onClick={() => history.push(urls.getCompany())}
      >
        Back to Companies list
      </Button>
    </div>
  );
};

const GoogleInput = ({ googleDocs, setGoogleDocs }) => {
  const [value, setValue] = useState(googleDocs);

  return (
    <Input
      size="large"
      value={value}
      label="Google Doc link"
      placeholder="Google Doc link"
      onChange={(e) => {
        setValue(e.target.value);
        sessionStorage.setItem('googleDocs', e.target.value);
      }}
    />
  );
};

export default General;
